@import url("http://fonts.googleapis.com/css?family=Open+Sans:300,300italic,600,600italic,700");
@import url("font-awesome.min.css");



/*Fonts*/
@font-face{
	font-family:'SonsieOne';
	src:url('../fonts/SonsieOne-Regular.otf');
}

@font-face { 
    font-family: 'Wolgast'; 
    src: url('../fonts/Wolgast Two Normal Cyr.ttf?v=2'); 
    font-weight: normal; 
    font-style: normal; 
}



h1.logoh{
	font-family:'SonsieOne';
	color: rgb(255, 204, 102);
	font-size: 4em;
	text-shadow: -1px 0 #be6127, 0 1px #be6127, 1px 0 #be6127, 0 -1px #be6127;
	text-align: left;
}

.fit img{
	-ms-transform: rotate(40deg);
	-webkit-transform: rotate(40deg);
	transform: rotate(40deg);
}

/*
	Miniport by HTML5 UP
	html5up.net | @ajlkn
	Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
*/

/* Reset */

	html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
		margin: 0;
		padding: 0;
		border: 0;
		font-size: 100%;
		font: inherit;
		vertical-align: baseline;
	}

	article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
		display: block;
	}

	body {
		line-height: 1;
	}

	ol, ul {
		list-style: none;
	}

	blockquote, q {
		quotes: none;
	}

	blockquote:before, blockquote:after, q:before, q:after {
		content: '';
		content: none;
	}

	table {
		border-collapse: collapse;
		border-spacing: 0;
	}

	body {
		-webkit-text-size-adjust: none;
	}

/* Box Model */

	*, *:before, *:after {
		-moz-box-sizing: border-box;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
	}

/* Containers */

	.container {
		margin-left: auto;
		margin-right: auto;
	}

	.container.\31 25\25 {
		width: 100%;
		max-width: 1200px;
		min-width: 960px;
	}

	.container.\37 5\25 {
		width: 720px;
	}

	.container.\35 0\25 {
		width: 480px;
	}

	.container.\32 5\25 {
		width: 240px;
	}

	.container {
		width: 960px;
	}

	@media screen and (min-width: 737px) {

		.container.\31 25\25 {
			width: 100%;
			max-width: 1500px;
			min-width: 1200px;
		}

		.container.\37 5\25 {
			width: 900px;
		}

		.container.\35 0\25 {
			width: 600px;
		}

		.container.\32 5\25 {
			width: 300px;
		}

		.container {
			width: 1200px;
		}

	}

	@media screen and (min-width: 737px) and (max-width: 1200px) {

		.container.\31 25\25 {
			width: 100%;
			max-width: 1200px;
			min-width: 960px;
		}

		.container.\37 5\25 {
			width: 720px;
		}

		.container.\35 0\25 {
			width: 480px;
		}

		.container.\32 5\25 {
			width: 240px;
		}

		.container {
			width: 960px;
		}

	}

	@media screen and (max-width: 736px) {

		.container.\31 25\25 {
			width: 100%;
			max-width: 125%;
			min-width: 100%;
		}

		.container.\37 5\25 {
			width: 75%;
		}

		.container.\35 0\25 {
			width: 50%;
		}

		.container.\32 5\25 {
			width: 25%;
		}

		.container {
			width: 100% !important;
		}

	}

/* Grid */

	.row {
		border-bottom: solid 1px transparent;
		-moz-box-sizing: border-box;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
	}

	.row > * {
		float: left;
		-moz-box-sizing: border-box;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
	}

	.row:after, .row:before {
		content: '';
		display: block;
		clear: both;
		height: 0;
	}

	.row.uniform > * > :first-child {
		margin-top: 0;
	}

	.row.uniform > * > :last-child {
		margin-bottom: 0;
	}

	.row.\30 \25 > * {
		padding: 0 0 0 0px;
	}

	.row.\30 \25 {
		margin: 0 0 -1px 0px;
	}

	.row.uniform.\30 \25 > * {
		padding: 0px 0 0 0px;
	}

	.row.uniform.\30 \25 {
		margin: 0px 0 -1px 0px;
	}

	.row > * {
		padding: 0 0 0 40px;
	}

	.row {
		margin: 0 0 -1px -40px;
	}

	.row.uniform > * {
		padding: 40px 0 0 40px;
	}

	.row.uniform {
		margin: -40px 0 -1px -40px;
	}

	.row.\32 00\25 > * {
		padding: 0 0 0 80px;
	}

	.row.\32 00\25 {
		margin: 0 0 -1px -80px;
	}

	.row.uniform.\32 00\25 > * {
		padding: 80px 0 0 80px;
	}

	.row.uniform.\32 00\25 {
		margin: -80px 0 -1px -80px;
	}

	.row.\31 50\25 > * {
		padding: 0 0 0 60px;
	}

	.row.\31 50\25 {
		margin: 0 0 -1px -60px;
	}

	.row.uniform.\31 50\25 > * {
		padding: 60px 0 0 60px;
	}

	.row.uniform.\31 50\25 {
		margin: -60px 0 -1px -60px;
	}

	.row.\35 0\25 > * {
		padding: 0 0 0 20px;
	}

	.row.\35 0\25 {
		margin: 0 0 -1px -20px;
	}

	.row.uniform.\35 0\25 > * {
		padding: 20px 0 0 20px;
	}

	.row.uniform.\35 0\25 {
		margin: -20px 0 -1px -20px;
	}

	.row.\32 5\25 > * {
		padding: 0 0 0 10px;
	}

	.row.\32 5\25 {
		margin: 0 0 -1px -10px;
	}

	.row.uniform.\32 5\25 > * {
		padding: 10px 0 0 10px;
	}

	.row.uniform.\32 5\25 {
		margin: -10px 0 -1px -10px;
	}

	.\31 2u, .\31 2u\24 {
		width: 100%;
		clear: none;
		margin-left: 0;
	}

	.\31 1u, .\31 1u\24 {
		width: 91.6666666667%;
		clear: none;
		margin-left: 0;
	}

	.\31 0u, .\31 0u\24 {
		width: 83.3333333333%;
		clear: none;
		margin-left: 0;
	}

	.\39 u, .\39 u\24 {
		width: 75%;
		clear: none;
		margin-left: 0;
	}

	.\38 u, .\38 u\24 {
		width: 66.6666666667%;
		clear: none;
		margin-left: 0;
	}

	.\37 u, .\37 u\24 {
		width: 58.3333333333%;
		clear: none;
		margin-left: 0;
	}

	.\36 u, .\36 u\24 {
		width: 50%;
		clear: none;
		margin-left: 0;
	}

	.\35 u, .\35 u\24 {
		width: 41.6666666667%;
		clear: none;
		margin-left: 0;
	}

	.\34 u, .\34 u\24 {
		width: 33.3333333333%;
		clear: none;
		margin-left: 0;
	}

	.\33 u, .\33 u\24 {
		width: 25%;
		clear: none;
		margin-left: 0;
	}

	.\32 u, .\32 u\24 {
		width: 16.6666666667%;
		clear: none;
		margin-left: 0;
	}

	.\31 u, .\31 u\24 {
		width: 8.3333333333%;
		clear: none;
		margin-left: 0;
	}

	.\31 2u\24 + *,
	.\31 1u\24 + *,
	.\31 0u\24 + *,
	.\39 u\24 + *,
	.\38 u\24 + *,
	.\37 u\24 + *,
	.\36 u\24 + *,
	.\35 u\24 + *,
	.\34 u\24 + *,
	.\33 u\24 + *,
	.\32 u\24 + *,
	.\31 u\24 + * {
		clear: left;
	}

	.\-11u {
		margin-left: 91.66667%;
	}

	.\-10u {
		margin-left: 83.33333%;
	}

	.\-9u {
		margin-left: 75%;
	}

	.\-8u {
		margin-left: 66.66667%;
	}

	.\-7u {
		margin-left: 58.33333%;
	}

	.\-6u {
		margin-left: 50%;
	}

	.\-5u {
		margin-left: 41.66667%;
	}

	.\-4u {
		margin-left: 33.33333%;
	}

	.\-3u {
		margin-left: 25%;
	}

	.\-2u {
		margin-left: 16.66667%;
	}

	.\-1u {
		margin-left: 8.33333%;
	}

	@media screen and (min-width: 737px) {

		.row > * {
			padding: 25px 0 0 25px;
		}

		.row {
			margin: -25px 0 -1px -25px;
		}

		.row.uniform > * {
			padding: 25px 0 0 25px;
		}

		.row.uniform {
			margin: -25px 0 -1px -25px;
		}

		.row.\32 00\25 > * {
			padding: 50px 0 0 50px;
		}

		.row.\32 00\25 {
			margin: -50px 0 -1px -50px;
		}

		.row.uniform.\32 00\25 > * {
			padding: 50px 0 0 50px;
		}

		.row.uniform.\32 00\25 {
			margin: -50px 0 -1px -50px;
		}

		.row.\31 50\25 > * {
			padding: 37.5px 0 0 37.5px;
		}

		.row.\31 50\25 {
			margin: -37.5px 0 -1px -37.5px;
		}

		.row.uniform.\31 50\25 > * {
			padding: 37.5px 0 0 37.5px;
		}

		.row.uniform.\31 50\25 {
			margin: -37.5px 0 -1px -37.5px;
		}

		.row.\35 0\25 > * {
			padding: 12.5px 0 0 12.5px;
		}

		.row.\35 0\25 {
			margin: -12.5px 0 -1px -12.5px;
		}

		.row.uniform.\35 0\25 > * {
			padding: 12.5px 0 0 12.5px;
		}

		.row.uniform.\35 0\25 {
			margin: -12.5px 0 -1px -12.5px;
		}

		.row.\32 5\25 > * {
			padding: 6.25px 0 0 6.25px;
		}

		.row.\32 5\25 {
			margin: -6.25px 0 -1px -6.25px;
		}

		.row.uniform.\32 5\25 > * {
			padding: 6.25px 0 0 6.25px;
		}

		.row.uniform.\32 5\25 {
			margin: -6.25px 0 -1px -6.25px;
		}

		.\31 2u\28desktop\29, .\31 2u\24\28desktop\29 {
			width: 100%;
			clear: none;
			margin-left: 0;
		}

		.\31 1u\28desktop\29, .\31 1u\24\28desktop\29 {
			width: 91.6666666667%;
			clear: none;
			margin-left: 0;
		}

		.\31 0u\28desktop\29, .\31 0u\24\28desktop\29 {
			width: 83.3333333333%;
			clear: none;
			margin-left: 0;
		}

		.\39 u\28desktop\29, .\39 u\24\28desktop\29 {
			width: 75%;
			clear: none;
			margin-left: 0;
		}

		.\38 u\28desktop\29, .\38 u\24\28desktop\29 {
			width: 66.6666666667%;
			clear: none;
			margin-left: 0;
		}

		.\37 u\28desktop\29, .\37 u\24\28desktop\29 {
			width: 58.3333333333%;
			clear: none;
			margin-left: 0;
		}

		.\36 u\28desktop\29, .\36 u\24\28desktop\29 {
			width: 50%;
			clear: none;
			margin-left: 0;
		}

		.\35 u\28desktop\29, .\35 u\24\28desktop\29 {
			width: 41.6666666667%;
			clear: none;
			margin-left: 0;
		}

		.\34 u\28desktop\29, .\34 u\24\28desktop\29 {
			width: 33.3333333333%;
			clear: none;
			margin-left: 0;
		}

		.\33 u\28desktop\29, .\33 u\24\28desktop\29 {
			width: 25%;
			clear: none;
			margin-left: 0;
		}

		.\32 u\28desktop\29, .\32 u\24\28desktop\29 {
			width: 16.6666666667%;
			clear: none;
			margin-left: 0;
		}

		.\31 u\28desktop\29, .\31 u\24\28desktop\29 {
			width: 8.3333333333%;
			clear: none;
			margin-left: 0;
		}

		.\31 2u\24\28desktop\29 + *,
		.\31 1u\24\28desktop\29 + *,
		.\31 0u\24\28desktop\29 + *,
		.\39 u\24\28desktop\29 + *,
		.\38 u\24\28desktop\29 + *,
		.\37 u\24\28desktop\29 + *,
		.\36 u\24\28desktop\29 + *,
		.\35 u\24\28desktop\29 + *,
		.\34 u\24\28desktop\29 + *,
		.\33 u\24\28desktop\29 + *,
		.\32 u\24\28desktop\29 + *,
		.\31 u\24\28desktop\29 + * {
			clear: left;
		}

		.\-11u\28desktop\29 {
			margin-left: 91.66667%;
		}

		.\-10u\28desktop\29 {
			margin-left: 83.33333%;
		}

		.\-9u\28desktop\29 {
			margin-left: 75%;
		}

		.\-8u\28desktop\29 {
			margin-left: 66.66667%;
		}

		.\-7u\28desktop\29 {
			margin-left: 58.33333%;
		}

		.\-6u\28desktop\29 {
			margin-left: 50%;
		}

		.\-5u\28desktop\29 {
			margin-left: 41.66667%;
		}

		.\-4u\28desktop\29 {
			margin-left: 33.33333%;
		}

		.\-3u\28desktop\29 {
			margin-left: 25%;
		}

		.\-2u\28desktop\29 {
			margin-left: 16.66667%;
		}

		.\-1u\28desktop\29 {
			margin-left: 8.33333%;
		}

	}

	@media screen and (min-width: 737px) and (max-width: 1200px) {

		.row > * {
			padding: 25px 0 0 25px;
		}

		.row {
			margin: -25px 0 -1px -25px;
		}

		.row.uniform > * {
			padding: 25px 0 0 25px;
		}

		.row.uniform {
			margin: -25px 0 -1px -25px;
		}

		.row.\32 00\25 > * {
			padding: 50px 0 0 50px;
		}

		.row.\32 00\25 {
			margin: -50px 0 -1px -50px;
		}

		.row.uniform.\32 00\25 > * {
			padding: 50px 0 0 50px;
		}

		.row.uniform.\32 00\25 {
			margin: -50px 0 -1px -50px;
		}

		.row.\31 50\25 > * {
			padding: 37.5px 0 0 37.5px;
		}

		.row.\31 50\25 {
			margin: -37.5px 0 -1px -37.5px;
		}

		.row.uniform.\31 50\25 > * {
			padding: 37.5px 0 0 37.5px;
		}

		.row.uniform.\31 50\25 {
			margin: -37.5px 0 -1px -37.5px;
		}

		.row.\35 0\25 > * {
			padding: 12.5px 0 0 12.5px;
		}

		.row.\35 0\25 {
			margin: -12.5px 0 -1px -12.5px;
		}

		.row.uniform.\35 0\25 > * {
			padding: 12.5px 0 0 12.5px;
		}

		.row.uniform.\35 0\25 {
			margin: -12.5px 0 -1px -12.5px;
		}

		.row.\32 5\25 > * {
			padding: 6.25px 0 0 6.25px;
		}

		.row.\32 5\25 {
			margin: -6.25px 0 -1px -6.25px;
		}

		.row.uniform.\32 5\25 > * {
			padding: 6.25px 0 0 6.25px;
		}

		.row.uniform.\32 5\25 {
			margin: -6.25px 0 -1px -6.25px;
		}

		.\31 2u\28tablet\29, .\31 2u\24\28tablet\29 {
			width: 100%;
			clear: none;
			margin-left: 0;
		}

		.\31 1u\28tablet\29, .\31 1u\24\28tablet\29 {
			width: 91.6666666667%;
			clear: none;
			margin-left: 0;
		}

		.\31 0u\28tablet\29, .\31 0u\24\28tablet\29 {
			width: 83.3333333333%;
			clear: none;
			margin-left: 0;
		}

		.\39 u\28tablet\29, .\39 u\24\28tablet\29 {
			width: 75%;
			clear: none;
			margin-left: 0;
		}

		.\38 u\28tablet\29, .\38 u\24\28tablet\29 {
			width: 66.6666666667%;
			clear: none;
			margin-left: 0;
		}

		.\37 u\28tablet\29, .\37 u\24\28tablet\29 {
			width: 58.3333333333%;
			clear: none;
			margin-left: 0;
		}

		.\36 u\28tablet\29, .\36 u\24\28tablet\29 {
			width: 50%;
			clear: none;
			margin-left: 0;
		}

		.\35 u\28tablet\29, .\35 u\24\28tablet\29 {
			width: 41.6666666667%;
			clear: none;
			margin-left: 0;
		}

		.\34 u\28tablet\29, .\34 u\24\28tablet\29 {
			width: 33.3333333333%;
			clear: none;
			margin-left: 0;
		}

		.\33 u\28tablet\29, .\33 u\24\28tablet\29 {
			width: 25%;
			clear: none;
			margin-left: 0;
		}

		.\32 u\28tablet\29, .\32 u\24\28tablet\29 {
			width: 16.6666666667%;
			clear: none;
			margin-left: 0;
		}

		.\31 u\28tablet\29, .\31 u\24\28tablet\29 {
			width: 8.3333333333%;
			clear: none;
			margin-left: 0;
		}

		.\31 2u\24\28tablet\29 + *,
		.\31 1u\24\28tablet\29 + *,
		.\31 0u\24\28tablet\29 + *,
		.\39 u\24\28tablet\29 + *,
		.\38 u\24\28tablet\29 + *,
		.\37 u\24\28tablet\29 + *,
		.\36 u\24\28tablet\29 + *,
		.\35 u\24\28tablet\29 + *,
		.\34 u\24\28tablet\29 + *,
		.\33 u\24\28tablet\29 + *,
		.\32 u\24\28tablet\29 + *,
		.\31 u\24\28tablet\29 + * {
			clear: left;
		}

		.\-11u\28tablet\29 {
			margin-left: 91.66667%;
		}

		.\-10u\28tablet\29 {
			margin-left: 83.33333%;
		}

		.\-9u\28tablet\29 {
			margin-left: 75%;
		}

		.\-8u\28tablet\29 {
			margin-left: 66.66667%;
		}

		.\-7u\28tablet\29 {
			margin-left: 58.33333%;
		}

		.\-6u\28tablet\29 {
			margin-left: 50%;
		}

		.\-5u\28tablet\29 {
			margin-left: 41.66667%;
		}

		.\-4u\28tablet\29 {
			margin-left: 33.33333%;
		}

		.\-3u\28tablet\29 {
			margin-left: 25%;
		}

		.\-2u\28tablet\29 {
			margin-left: 16.66667%;
		}

		.\-1u\28tablet\29 {
			margin-left: 8.33333%;
		}

	}

	@media screen and (max-width: 736px) {

		.row > * {
			padding: 15px 0 0 15px;
		}

		.row {
			margin: -15px 0 -1px -15px;
		}

		.row.uniform > * {
			padding: 15px 0 0 15px;
		}

		.row.uniform {
			margin: -15px 0 -1px -15px;
		}

		.row.\32 00\25 > * {
			padding: 30px 0 0 30px;
		}

		.row.\32 00\25 {
			margin: -30px 0 -1px -30px;
		}

		.row.uniform.\32 00\25 > * {
			padding: 30px 0 0 30px;
		}

		.row.uniform.\32 00\25 {
			margin: -30px 0 -1px -30px;
		}

		.row.\31 50\25 > * {
			padding: 22.5px 0 0 22.5px;
		}

		.row.\31 50\25 {
			margin: -22.5px 0 -1px -22.5px;
		}

		.row.uniform.\31 50\25 > * {
			padding: 22.5px 0 0 22.5px;
		}

		.row.uniform.\31 50\25 {
			margin: -22.5px 0 -1px -22.5px;
		}

		.row.\35 0\25 > * {
			padding: 7.5px 0 0 7.5px;
		}

		.row.\35 0\25 {
			margin: -7.5px 0 -1px -7.5px;
		}

		.row.uniform.\35 0\25 > * {
			padding: 7.5px 0 0 7.5px;
		}

		.row.uniform.\35 0\25 {
			margin: -7.5px 0 -1px -7.5px;
		}

		.row.\32 5\25 > * {
			padding: 3.75px 0 0 3.75px;
		}

		.row.\32 5\25 {
			margin: -3.75px 0 -1px -3.75px;
		}

		.row.uniform.\32 5\25 > * {
			padding: 3.75px 0 0 3.75px;
		}

		.row.uniform.\32 5\25 {
			margin: -3.75px 0 -1px -3.75px;
		}

		.\31 2u\28mobile\29, .\31 2u\24\28mobile\29 {
			width: 100%;
			clear: none;
			margin-left: 0;
		}

		.\31 1u\28mobile\29, .\31 1u\24\28mobile\29 {
			width: 91.6666666667%;
			clear: none;
			margin-left: 0;
		}

		.\31 0u\28mobile\29, .\31 0u\24\28mobile\29 {
			width: 83.3333333333%;
			clear: none;
			margin-left: 0;
		}

		.\39 u\28mobile\29, .\39 u\24\28mobile\29 {
			width: 75%;
			clear: none;
			margin-left: 0;
		}

		.\38 u\28mobile\29, .\38 u\24\28mobile\29 {
			width: 66.6666666667%;
			clear: none;
			margin-left: 0;
		}

		.\37 u\28mobile\29, .\37 u\24\28mobile\29 {
			width: 58.3333333333%;
			clear: none;
			margin-left: 0;
		}

		.\36 u\28mobile\29, .\36 u\24\28mobile\29 {
			width: 50%;
			clear: none;
			margin-left: 0;
		}

		.\35 u\28mobile\29, .\35 u\24\28mobile\29 {
			width: 41.6666666667%;
			clear: none;
			margin-left: 0;
		}

		.\34 u\28mobile\29, .\34 u\24\28mobile\29 {
			width: 33.3333333333%;
			clear: none;
			margin-left: 0;
		}

		.\33 u\28mobile\29, .\33 u\24\28mobile\29 {
			width: 25%;
			clear: none;
			margin-left: 0;
		}

		.\32 u\28mobile\29, .\32 u\24\28mobile\29 {
			width: 16.6666666667%;
			clear: none;
			margin-left: 0;
		}

		.\31 u\28mobile\29, .\31 u\24\28mobile\29 {
			width: 8.3333333333%;
			clear: none;
			margin-left: 0;
		}

		.\31 2u\24\28mobile\29 + *,
		.\31 1u\24\28mobile\29 + *,
		.\31 0u\24\28mobile\29 + *,
		.\39 u\24\28mobile\29 + *,
		.\38 u\24\28mobile\29 + *,
		.\37 u\24\28mobile\29 + *,
		.\36 u\24\28mobile\29 + *,
		.\35 u\24\28mobile\29 + *,
		.\34 u\24\28mobile\29 + *,
		.\33 u\24\28mobile\29 + *,
		.\32 u\24\28mobile\29 + *,
		.\31 u\24\28mobile\29 + * {
			clear: left;
		}

		.\-11u\28mobile\29 {
			margin-left: 91.66667%;
		}

		.\-10u\28mobile\29 {
			margin-left: 83.33333%;
		}

		.\-9u\28mobile\29 {
			margin-left: 75%;
		}

		.\-8u\28mobile\29 {
			margin-left: 66.66667%;
		}

		.\-7u\28mobile\29 {
			margin-left: 58.33333%;
		}

		.\-6u\28mobile\29 {
			margin-left: 50%;
		}

		.\-5u\28mobile\29 {
			margin-left: 41.66667%;
		}

		.\-4u\28mobile\29 {
			margin-left: 33.33333%;
		}

		.\-3u\28mobile\29 {
			margin-left: 25%;
		}

		.\-2u\28mobile\29 {
			margin-left: 16.66667%;
		}

		.\-1u\28mobile\29 {
			margin-left: 8.33333%;
		}

	}

/* Basic */

	body, input, textarea, select {
		font-family: 'Open Sans', sans-serif;
		line-height: 1.85em;
		color: #000;
		font-weight: 300;
	}

		body.is-loading *, input.is-loading *, textarea.is-loading *, select.is-loading * {
			-moz-transition: none !important;
			-webkit-transition: none !important;
			-ms-transition: none !important;
			transition: none !important;
			-moz-animation: none !important;
			-webkit-animation: none !important;
			-ms-animation: none !important;
			animation: none !important;
		}

	a {
		-moz-transition: color .2s ease-in-out;
		-webkit-transition: color .2s ease-in-out;
		-ms-transition: color .2s ease-in-out;
		transition: color .2s ease-in-out;
		color: #43B3E0;
		text-decoration: underline;
	}

		a:hover {
			color: #43bff0 !important;
		}

		a img {
			border: 0;
		}

	b, strong {
		font-weight: 600;
		color: #3e3e3e;
	}

	i, em {
		font-style: italic;
	}

	sub {
		position: relative;
		top: 0.5em;
		font-size: 0.8em;
	}

	sup {
		position: relative;
		top: -0.5em;
		font-size: 0.8em;
	}

	blockquote {
		border-left: solid 0.75em #eee;
		padding: 1em 0 1em 1.5em;
		font-style: italic;
	}

	h1, h2, h3, h4, h5, h6 {
		color: #3e3e3e;
	}

	h1 a, h2 a, h3 a, h4 a, h5 a, h6 a {
		text-decoration: none;
		color: inherit;
	}

	h2, h3, h4, h5, h6 {
		font-weight: 700;
	}

	h1 {
		font-weight: 300;
	}

		h1 strong {
			font-weight: 700;
		}

	em, i {
		font-style: italic;
	}

	br.clear {
		clear: both;
	}

	hr {
		border: 0;
		border-top: solid 1px #444;
		border-top-color: rgba(0, 0, 0, 0.35);
		box-shadow: 0px 1px 0px 0px rgba(255, 255, 255, 0.1);
		height: 1px;
	    margin: 1em 0 1em 0;
	}

	p, ul, ol, dl, table {
		margin-bottom: 1em;
	}

/* Form */

	form label {
		color: #3e3e3e;
		font-weight: 700;
		display: block;
		margin: 0 0 0.5em 0;
	}

	form input[type=text],
	form input[type=email],
	form input[type=password],
	form select,
	form textarea {
		-moz-transition: background .2s ease-in-out, box-shadow .2s ease-in-out;
		-webkit-transition: background .2s ease-in-out, box-shadow .2s ease-in-out;
		-ms-transition: background .2s ease-in-out, box-shadow .2s ease-in-out;
		transition: background .2s ease-in-out, box-shadow .2s ease-in-out;
		-webkit-appearance: none;
		display: block;
		border: 0;
		padding: 0.75em;
		font-size: 1em;
		border-radius: 8px;
		border: solid 1px #ddd;
		background: #fff;
		color: #bbb;
		box-shadow: inset 0px 2px 3px 1px rgba(0, 0, 0, 0.05), 0px 1px 0px 0px rgba(255, 255, 255, 0.025);
		width: 100%;
	}

		form input[type=text]:focus,
		form input[type=email]:focus,
		form input[type=password]:focus,
		form select:focus,
		form textarea:focus {
			background: #fafafa;
			box-shadow: inset 0px 2px 5px 0px rgba(0, 0, 0, 0.05), 0px 1px 0px 0px rgba(255, 255, 255, 0.025), inset 0px 0px 2px 1px #43bff0;
		}

	form textarea {
		height: 15em;
	}

	form .formerize-placeholder {
		color: #555 !important;
	}

	form ::-webkit-input-placeholder {
		color: #555 !important;
	}

	form :-moz-placeholder {
		color: #555 !important;
	}

	form ::-moz-placeholder {
		color: #555 !important;
	}

	form :-ms-input-placeholder {
		color: #555 !important;
	}

	form ::-moz-focus-inner {
		border: 0;
	}

/* Tables */

	table {
		width: 100%;
	}

		table.default {
			width: 100%;
		}

			table.default tr {
				border-top: solid 1px #eee;
			}

				table.default tr:first-child {
					border-top: 0;
				}

			table.default td {
				padding: 0.5em 1em 0.5em 1em;
			}

			table.default th {
				text-align: left;
				padding: 0.5em 1em 0.5em 1em;
				font-weight: 600;
				margin: 0 0 1em 0;
			}

			table.default thead {
				background: #4f4f4f;
				color: #fff;
			}

/* Section/Article */

	section, article {
		margin-bottom: 3em;
	}

	section > :last-child,
	article > :last-child,
	section:last-child,
	article:last-child {
		margin-bottom: 0;
	}

/* Image */

	.image {
		display: inline-block;
		position: relative;
	}

		.image img {
			display: block;
			width: 100%;
		}

		.image.fit {
			display: block;
			width: 100%;
		}

		.image.featured {
			display: block;
			width: 100%;
			margin: 0 0 2em 0;
		}

		.image.left {
			float: left;
			margin: 0 2em 2em 0;
		}

		.image.centered {
			display: block;
			margin: 0 0 2em 0;
		}

			.image.centered img {
				margin: 0 auto;
				width: auto;
			}

/* Button */

	input[type="button"],
	input[type="submit"],
	input[type="reset"],
	button,
	.button {
		/*background-image: url("images/bg.png"), -moz-linear-gradient(top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.2));
		background-image: url("images/bg.png"), -webkit-linear-gradient(top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.2));
		background-image: url("images/bg.png"), -ms-linear-gradient(top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.2));
		background-image: url("images/bg.png"), linear-gradient(top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.2));*/
		-moz-transition: background-color .2s ease-in-out;
		-webkit-transition: background-color .2s ease-in-out;
		-ms-transition: background-color .2s ease-in-out;
		transition: background-color .2s ease-in-out;
		-webkit-appearance: none;
		position: relative;
		display: inline-block;
		color: rgb(255, 204, 102) !important;
		text-decoration: none;
		font-weight: 700;
		border: 0;
		outline: 0;
		cursor: pointer;
		border-radius: 8px;
		text-shadow: -1px -1px 0.5px rgba(0, 0, 0, 0.5);
		overflow: hidden;
		box-shadow: inset 0px 0px 0px 1px rgba(85, 85, 43, 0.8), inset 0px 2px 1px 0px rgba(255, 204, 102, 0.75);
		background-color: rgba(139, 132, 41, 0.7);
	}

		input[type="button"]:hover,
		input[type="submit"]:hover,
		input[type="reset"]:hover,
		button:hover,
		.button:hover {
			background-color: rgb(139, 132, 41);
			color: rgb(255, 204, 102) !important;
		}

		input[type="button"]:active,
		input[type="submit"]:active,
		input[type="reset"]:active,
		button:active,
		.button:active {
			/*background-color: #3BA8D3;*/
			top: 1px;
		}

		input[type="button"].alt,
		input[type="submit"].alt,
		input[type="reset"].alt,
		button.alt,
		.button.alt {
			background-color: #444;
			box-shadow: inset 0px 0px 0px 1px #242424, inset 0px 2px 1px 0px rgba(255, 255, 255, 0.2);
		}

			input[type="button"].alt:hover,
			input[type="submit"].alt:hover,
			input[type="reset"].alt:hover,
			button.alt:hover,
			.button.alt:hover {
				background-color: #4f4f4f;
				/*color: #fff !important;*/
			}

			input[type="button"].alt:active,
			input[type="submit"].alt:active,
			input[type="reset"].alt:active,
			button.alt:active,
			.button.alt:active {
				background-color: #3f3f3f;
			}

/* List */

	ul.default {
		list-style: disc;
		padding-left: 1em;
	}

		ul.default li {
			padding-left: 0.5em;
		}

	ul.social {
		cursor: default;
		margin: 0;
	}

		ul.social li {
			position: relative;
			display: inline-block;
			margin: 0.25em;
			top: 0;
		}

			ul.social li a {
				-moz-transition: top .2s ease-in-out;
				-webkit-transition: top .2s ease-in-out;
				-ms-transition: top .2s ease-in-out;
				transition: top .2s ease-in-out;
				display: block;
				width: 48px;
				height: 48px;
				border-radius: 6px;
				top: 0;
				position: relative;
			}

				ul.social li a:before {
					background-image: url("../images/bg.png"), -moz-linear-gradient(top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.2));
					background-image: url("../images/bg.png"), -webkit-linear-gradient(top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.2));
					background-image: url("../images/bg.png"), -ms-linear-gradient(top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.2));
					background-image: url("../images/bg.png"), linear-gradient(top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.2));
					-moz-transition: background-color .2s ease-in-out;
					-webkit-transition: background-color .2s ease-in-out;
					-ms-transition: background-color .2s ease-in-out;
					transition: background-color .2s ease-in-out;
					background-color: #444;
					border-radius: 6px;
					box-shadow: inset 0px 0px 0px 1px #282828, inset 0px 2px 1px 0px rgba(255, 255, 255, 0.1);
					color: #2E2E2E !important;
					display: block;
					font-size: 26px;
					height: 48px;
					line-height: 48px;
					text-align: center;
					outline: 0;
					overflow: hidden;
					text-shadow: 1px 1px 0px rgba(255, 255, 255, 0.1);
					width: 48px;
				}

				ul.social li a.fa-twitter {
					background-color: #2DAAE4;
				}

				ul.social li a.fa-facebook {
					background-color: #3C5A98;
				}

				ul.social li a.fa-dribbble {
					background-color: #C4376B;
				}

				ul.social li a.fa-linkedin {
					background-color: #006599;
				}

				ul.social li a.fa-tumblr {
					background-color: #51718A;
				}

				ul.social li a.fa-google-plus {
					background-color: #DA2713;
				}

				ul.social li a.fa-github {
					background-color: #FAFAFA;
				}

				ul.social li a.fa-rss {
					background-color: #F2600B;
				}

				ul.social li a.fa-instagram {
					background-color: #E0D7C8;
				}

				ul.social li a.fa-foursquare {
					background-color: #39A3D4;
				}

				ul.social li a.fa-skype {
					background-color: #10BEF1;
				}

				ul.social li a.fa-soundcloud {
					background-color: #FE5419;
				}

				ul.social li a.fa-youtube {
					background-color: #BF2E25;
				}

				ul.social li a.fa-blogger {
					background-color: #FF6501;
				}

				ul.social li a.fa-flickr {
					background-color: #0062DB;
				}

				ul.social li a.fa-vimeo {
					background-color: #4C8AB0;
				}

				ul.social li a:hover {
					top: -5px;
				}

					ul.social li a:hover:before {
						background-color: transparent;
					}

	ul.actions li {
		display: inline-block;
		margin: 0 0 0 1em;
	}

		ul.actions li:first-child {
			margin-left: 0;
		}

	ol.default {
		list-style: decimal;
		padding-left: 1.25em;
	}

		ol.default li {
			padding-left: 0.25em;
		}

/* Box */

	.box {
		background: #fff;
		box-shadow: inset 0px 0px 0px 1px rgba(0, 0, 0, 0.15), 0px 2px 3px 0px rgba(0, 0, 0, 0.1);
		/*text-align: center;*/
	}

/* Icons */

	.icon {
		text-decoration: none;
	}

		.icon:before {
			display: inline-block;
			font-family: FontAwesome;
			font-size: 1.25em;
			text-decoration: none;
			font-style: normal;
			font-weight: normal;
			line-height: 1;
			-webkit-font-smoothing: antialiased;
			-moz-osx-font-smoothing: grayscale;
		}

		.icon > .label {
			display: none;
		}

		.icon.featured {
			color: #EA8A95;
			display: block;
			margin: 0 0 1.5em 0;
			cursor: default;
		}

			.icon.featured:before {
				font-size: 6em;
			}

/* Wrappers */

	.wrapper {
		/*background-image: url("images/bg.png");*/
		box-shadow: inset 0px 1px 0px 0px rgba(0, 0, 0, 0.05), inset 0px 2px 3px 0px rgba(0, 0, 0, 0.1);
	}

		.wrapper.first {
			box-shadow: none;
		}

		.wrapper.style1 {
			background: #FC6;
			background: -webkit-linear-gradient(#b85d26 , #FC6);
			background: -o-linear-gradient(#b85d26 , #FC6);
			background: -moz-linear-gradient(#b85d26 , #FC6);
			background: linear-gradient( #b85d26 , #FC6);
		}

		.wrapper.style2 {
			background-color: rgb(217, 217, 217);
			/*text-shadow: 1px 1px 0px #fff;*/
		}

		.wrapper.style3 {
			/*background-color: #f4f4f4;*/
			background: #FC6;
			background: -webkit-linear-gradient(#b85d26 , #FC6);
			background: -o-linear-gradient(#b85d26 , #FC6);
			background: -moz-linear-gradient(#b85d26 , #FC6);
			background: linear-gradient( #b85d26 , #FC6);
			/*text-shadow: 1px 1px 0px #fff;*/
		}

		.wrapper.style4 {
			background-color: #303030;
			color: #999;
			text-shadow: -1px -1px 0px #181818;
		}

			.wrapper.style4 h1, .wrapper.style4 h2, .wrapper.style4 h3, .wrapper.style4 h4, .wrapper.style4 h5, .wrapper.style4 h6 {
				color: #fff;
			}

			.wrapper.style4 form input[type=text],
			.wrapper.style4 form input[type=password],
			.wrapper.style4 form select,
			.wrapper.style4 form textarea {
				border: none;
				background: #282828;
			}

				.wrapper.style4 form input[type=text]:focus,
				.wrapper.style4 form input[type=password]:focus,
				.wrapper.style4 form select:focus,
				.wrapper.style4 form textarea:focus {
					background: #252525;
				}

				

/* Nav */

	#nav {
		background-color: rgb(190, 97, 39);
		text-align: center;
		position: fixed;
		left: 0;
		top: 0;
		width: 100%;
		z-index: 10000;
		cursor: default;
	}

		#nav ul {
			margin-bottom: 0;
		}

		#nav li {
			display: inline-block;
		}

		#nav a, span.drdown {
			position: relative;
			display: block;
			color: #fff;
			text-decoration: none;
			outline: 0;
			/*border: 1px solid #000;*/
		}

			/*#nav a:hover, span.drdown:hover {
				color: #fff !important;
			}*/

			#nav a.active:before {
				content: '';
				display: block;
				position: absolute;
				bottom: -0.6em;
				left: 50%;
				margin-left: -0.75em;
				border-left: solid 0.75em transparent;
				border-right: solid 0.75em transparent;
				border-top: solid 0.6em #282828;
			}

/* Articles */

	#top .image {
		border-radius: 50%;
	}

		#top .image img {
			border-radius: 50%;
			border: 2px solid #be6127;
		}

	#top p a {
		color: inherit;
	}

/* Copyright */

	#copyright {
		color: #666;
	}

		#copyright a {
			-moz-transition: color .2s ease-in-out;
			-webkit-transition: color .2s ease-in-out;
			-ms-transition: color .2s ease-in-out;
			transition: color .2s ease-in-out;
			color: inherit;
		}

			#copyright a:hover {
				color: #777;
			}

/* Desktop */

	@media screen and (min-width: 737px) {

		/* Basic */

			body, input, textarea, select {
				font-size: 14pt;
			}

			h1 {
				font-size: 3.25em;
				letter-spacing: -0.025em;
			}

			h2 {
				font-size: 2em;
				letter-spacing: -0.015em;
			}

			h3 {
				font-size: 1.5em;
				letter-spacing: -0.015em;
			}

			h1, h2, h3, h4, h5, h6 {
				margin: 0 0 0.75em 0;
			}

			header {
				margin: 0 0 3em 0;
			}

				header > p {
					font-size: 1.25em;
					margin: 0;
				}

			footer {
				margin: 3em 0 0 0;
			}

				footer > p {
					font-size: 1.25em;
				}

		/* Form */

			form input[type="button"],
			form input[type="submit"],
			form input[type="reset"],
			form button,
			form .button {
				margin: 0 0.5em 0 0.5em;
			}

		/* Button */

			input[type="button"],
			input[type="submit"],
			input[type="reset"],
			button,
			.button {
				padding: 1em 2.35em 1em 2.35em;
				font-size: 1.1em;
			}

				input[type="button"].big,
				input[type="submit"].big,
				input[type="reset"].big,
				button.big,
				.button.big {
					font-size: 1.5em;
					letter-spacing: -0.025em;
				}

		/* Box */

			.box {
				padding: 2em;
			}

				.box.style1 {
					padding: 3em 2em 3.5em 2em;
				}

					.box.style1 h3 {
						margin-bottom: 0.5em;
					}

				.box.style2 h3 {
					margin-bottom: 0.25em;
				}

				.box.style2 .image {
					position: relative;
					left: 2em;
					top: 2em;
					margin: -4em 0 4em -4em;
					width: auto;
				}

		/* Wrappers */

			.wrapper {
				padding: 8em 0 8em 0;
				/*text-align: center;*/
			}

				.wrapper.first {
					padding-top: 12em;
				}

		/* Nav */

			#nav a, span.drdown {
				-moz-transition: background-color .2s ease-in-out;
				-webkit-transition: background-color .2s ease-in-out;
				-ms-transition: background-color .2s ease-in-out;
				transition: background-color .2s ease-in-out;
				padding: 0.2em 1em 0.2em 1em;
				margin: 0.6em 0.2em 0.6em 0.2em;
				font-weight: 600;
				border-radius: 8px;
				color: rgb(255, 204, 102);
			}

				#nav a:hover, span.drdown:hover {
					background: rgba(255, 204, 102, 0.9);
					color: #b85d26 !important;
				}

				#nav a:active {
					background: #484848;
				}

		/* Articles */

			#top {
				text-align: left;
			}

				#top .image {
					width: 20em;
					height: 20em;
					margin: 0;
				}

				#top h1 {
					margin-top: 0.35em;
				}

				#top p {
					font-size: 1.5em;
					line-height: 1.75em;
				}

			#contact footer {
				font-size: 0.9em;
			}

		/* Copyright */

			#copyright {
				font-size: 1em;
				line-height: 1em;
			}

				#copyright li {
					display: inline-block;
					border-left: solid 1px rgba(0, 0, 0, 0.5);
					box-shadow: -1px 0px 0px 0px rgba(255, 255, 255, 0.1);
					padding: 0 0 0 1em;
					margin: 0 0 0 1em;
				}

					#copyright li:first-child {
						border: 0;
						box-shadow: none;
						padding-left: 0;
						margin-left: 0;
					}

	}

/* Tablet */

	@media screen and (min-width: 737px) and (max-width: 1200px) {

		/* Basic */

			body {
				min-width: 960px;
				font-size: 11pt;
			}

			input, textarea, select {
				font-size: 11pt;
			}

			header {
				margin: 0 0 4em 0;
			}

		/* Wrappers */

			.wrapper {
				padding: 5em 0 5em 0;
				text-align: center;
			}

				.wrapper.first {
					padding-top: 9em;
				}

				.wrapper.style4 .row-special {
					margin: 2em 0 0 0;
					padding: 2em 0 2em 0;
				}

		/* Articles */

			#top .image {
				width: 19.5em;
				height: 19.5em;
				margin: 0;
			}

	}

/* Mobile */

	@media screen and (max-width: 736px) {

		/* Basic */

			body, input, textarea, select {
				line-height: 1.75em;
				font-size: 10pt;
				letter-spacing: 0;
			}

			h1, h2, h3, h4, h5, h6 {
				font-size: 1.25em;
				margin: 0 0 0.4em 0;
			}

			h1 {
				font-size: 2.25em;
				line-height: 1.25em;
			}

			header {
				margin: 0 0 2em 0;
			}

				header > p {
					font-size: 1.25em;
				}

			footer {
				margin: 2.5em 0 0 0;
			}

				footer > p {
					font-size: 1.25em;
				}

			hr {
				margin: 1.5em 0 2em 0;
			}

		/* Section/Article */

			section, article {
				clear: both;
			}

		/* Button */

			input[type="button"],
			input[type="submit"],
			input[type="reset"],
			button,
			.button {
				text-align: center;
				font-size: 1.2em;
				width: 100%;
				padding: 1em 0 1em 0;
			}

		/* List */

			ul.social {
				padding: 1em 0.5em 0 0.5em;
			}

				ul.social li {
					margin: 0.5em 0.5em 0.5em 0.5em;
				}

					ul.social li a {
						top: 0 !important;
					}

						ul.social li a:before {
							background-color: transparent !important;
						}

			ul.actions {
				margin: 0;
			}

				ul.actions li {
					display: block;
					margin: 10px 0 0 0;
				}

					ul.actions li:first-child {
						margin-top: 0;
					}

		/* Box */

			.box {
				padding: 30px 20px 30px 20px;
				margin: 0 0 20px 0 !important;
			}

				.box h3 {
					margin-bottom: 0.25em;
				}

				.box .image.centered {
					margin-bottom: 1em;
				}

				.box .image.featured {
					position: relative;
					left: 20px;
					top: 20px;
					margin: -50px 0 50px -40px;
					width: auto;
				}

		/* Wrappers */

			.wrapper {
				padding: 3em 15px 3em 15px;
				text-align: center;
			}

				.wrapper.first {
					padding-top: 5em;
				}

		/* Nav */

			#nav a {
				padding: 0.5em;
			}

		/* Articles */

			#top .image {
				width: 70%;
				height: auto;
				margin: 0 auto 1.75em auto;
			}

			#contact footer {
				margin: 0;
			}

		/* Copyright */

			#copyright {
				font-size: 1em;
				margin: 0;
			}

				#copyright li {
					display: block;
				}

	}

	/*Lisko*/
.scroll-top-wrapper {
	/*display: none;*/
    font-size: 25px;
    position: fixed;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    text-align: center;
    z-index: 99999999;
    background-color: none;
    color: #eeeeee;
    width: 100px;
    height: 100px;
    line-height: 48px;
    right: 0;
    bottom: 30px;
    padding-top: 2px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
}

.scroll-top-wrapper.show {
    visibility: visible;
    cursor: pointer;
    opacity: 1.0;
}

.show {
    display: block !important;
}

.glyphicon {
    position: relative;
    top: 1px;
    display: inline-block;    
}

article header, article footer{
	text-align: center;
}

.style2{
	background-image: none;
	/*background: linear-gradient( #ffd480, #ffeecc);*/
}

/*Additional*/
input::-webkit-input-placeholder {
color: #be6127 !important;
}
 
input:-moz-placeholder { /* Firefox 18- */
color: #be6127 !important;  
}
 
input::-moz-placeholder {  /* Firefox 19+ */
color: #be6127 !important;  
}
 
input:-ms-input-placeholder {  
color: #be6127 !important;  
}

textarea::-webkit-input-placeholder {
color: #be6127 !important;
}
 
textarea:-moz-placeholder { /* Firefox 18- */
color: #be6127 !important;  
}
 
textarea::-moz-placeholder {  /* Firefox 19+ */
color: #be6127 !important;  
}
 
textarea:-ms-input-placeholder {  
color: #be6127 !important;  
}

article#contact header, article#contact header h2{
	color: #be6127;
}

.box.style1{
	border-radius: 20px;
}

.wrapper.style22 {
    background-color: #b3b3b3;

}

.quotauth{
	font-weight: bold;
	font-style: italic;
	color: #404040;
}

.sfm{
	background-color: #be6127 !important;
}

.sfm:hover{
	background-color: #d87b41 !important;
}

.user-profile{
	position: absolute;
	right: 5%;
}

.box.img_box{
	background: rgb(217, 217, 217);ъв
	min-height: 396px;
}

.fancybox-overlay {
    z-index: 10000 !important;
}


/*Dropotron*/

#nav ul { 
	list-style: none;
	margin: 0; 
	padding: 0;
}

#nav ul li { 
	display: inline-block; 
	margin: 0 0.1em 0 0.1em; 
	padding: 0.35em 0.15em 0.35em 0.15em; 
	border-radius: 0.5em;
}

/*#nav ul li.active { 
	background: #999; 
}*/

#nav ul li.active a{ 
	color: #b85d26;
	text-decoration: none; 
}

.dropotron { 
	background: rgb(255, 204, 102); 
	border-radius: 0 0 0.5em 0.5em; 
	list-style: none; 
	margin: -1px; 
	min-width: 10em; 
	padding: 0.75em 1em 0.75em 1em;
	border: 1px solid #be6127; 
}

.dropotron.level-0.right{
	margin: 1px;
	margin-top: -1px;
}

.dropotron > li { 
	border-top: solid 1px rgb(190, 97, 39); 
	margin: 0; 
	padding: 0; 
}

.dropotron > li:first-child { 
	border-top: 0; 
}

.dropotron > li > a { 
	color: rgb(190, 97, 39); 
	display: block; 
	padding: 0.5em 0 0.5em 0; 
	text-decoration: none; 
}


.dropotron > li.active > a, .dropotron > li:hover > a { 
	color: #fff; 
}

/*.dropotron.level-0 { 
	margin-top: 1.25em; 
}*/

.dropotron.level-0:before { 
	content: ''; 
	position: absolute; 
	border-bottom: solid 0.5em #444; 
	border-left: solid 0.5em transparent; 
	border-right: solid 0.5em transparent; 
	top: -0.5em; 
}

#nav ul li.opener.active{
	background: rgb(255, 204, 102);
	border-radius: 0.5em 0.5em 0 0;

}

.dropotron.level-0 a:hover{
	color: #fff !important;
}

/*Pagination*/
.paginatorWrapper{
	width: 100%;
	text-align: center;
}

ul.pagination{
	display: inline-block;
	padding: 0;
	margin: 50px auto;
}

ul.pagination li{
	display: inline;
	margin-right: 15px;
	text-align: center;
}

ul.pagination li a, ul.pagination li span{
    color: black;
    float: left;
    padding: 8px 16px;
    text-decoration: none;
}

.pagination li:first-child a, .pagination li:first-child span{
    border-top-left-radius: 7px;
    border-bottom-left-radius: 7px;
}

.pagination li:last-child a, .pagination li:last-child span{
    border-top-right-radius: 7px;
    border-bottom-right-radius: 7px;
}

ul.pagination li.active span{
    background-color: rgba(139, 132, 41, 0.7);
    color: rgba(255, 204, 102, 0.75);
}

ul.pagination li:hover:not(.active) {
	background-color: #ddd;
}

ul.pagination li a, ul.pagination li span{
	border: 1px solid rgb(190, 97, 39);
}

ul.pagination li a:hover{
	color: rgb(190, 97, 39) !important;
}


/*Slide-out menu*/
.nav-side{
	position: fixed;
	left: 0;
	top: 0;
	height: 500px;
	width: 100%;
	max-width: 250px;
	box-sizing: border-box;
	padding: 20px;
	color: #fff;
	background-color: rgb(139, 132, 41);
	z-index: 1;
	margin-left: -250px;
	transition: margin 200ms ease-in-out;
	display: none;
}

.nav-side.nav-open{
	margin-left: 0;
	box-shadow: 1px 0 3px rgba(0, 0, 0, .1);
}

.nav-side ul{
	margin-top: 30px;
	line-height: 40px;	
}

.nav-side ul li a{
	color: rgb(255, 204, 102);
	text-decoration: none;
	font-size: 1.4em;
}

.nav-side ul li a:hover{
	color: #fff !important;
}

.nav-toggle{
	position: absolute;
	right: -35px;
	top: 0;
	width: 35px;
	height: 35px;
	line-height: 40px;
	text-align: center;
	background-color: rgb(139, 132, 41);
	border-bottom-right-radius: 5px;
	box-shadow: 1px 0 3px rgba(0, 0, 0, .1);
}

.nav-toggle i{
	color: rgba(255, 204, 102, 0.75);
}

@media screen and (max-width: 1024px) {
    nav#nav{
    	display: none;
    }

    div.nav-side{
    	display: initial;
    }
}

/*News*/
section.newsstyle{
	padding: 1em !important;
}

section.newsstyle h3{
	text-align: center;
	border-bottom: 1px solid rgb(190, 97, 39);
}

section.newsstyle article{
	margin-bottom: 1em;
}

section.newsstyle article h4{
	font-size: 1em;
    margin-bottom: 0;
    line-height: 1.2em;
}

section.newsstyle article p{
	font-size: .82em;
    line-height: 1.2em;
    margin-top: 5px;
}

.current_item, .current_item h4 a{
	color: rgb(190, 97, 39);
}

.news_a{
	text-decoration: none;
}

a.news_a h4:hover, a.news_a p:hover{
	color: #992600 !important;
}

.newsstyle a p{
	color: #000;
}

.wrapper header h2{
	font-family: 'Wolgast';
	font-size: 2.2em;
}

.newsItemWraper a.fancyimg{
	display: inline;
}
